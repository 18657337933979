const { NEXT_PUBLIC_STRAPI_URL } = process.env;
const { NODE_ENV } = process.env;

interface AppConfig {
  STRAPI_URL: string;
  NODE_ENV: string;
}
export const Config: AppConfig = {
  STRAPI_URL:
    NEXT_PUBLIC_STRAPI_URL || `https://motorbikepacker-strapi.stg.uwizy.com`,
  NODE_ENV,
};
