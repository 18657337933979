import React from 'react';
import { SeoData } from 'models';
import { Helmet } from 'react-helmet';
import { Config } from '@/config';

interface SEOProps {
  seo: SeoData;
}

const Seo: React.FC<SEOProps> = ({ seo }) => {
  const fullSeo = {
    ...seo,
    // Add title suffix
    metaTitle: `${seo.metaTitle} | Motorbikepacker`,
    // Get full image URL
    shareImage:
      seo.shareImage?.localFile?.publicURL ||
      `${Config.STRAPI_URL}${seo.shareImage?.url}` ||
      `/default_meta_picture.png`,
  };

  return (
    <Helmet>
      <title>{fullSeo.metaTitle}</title>
      <meta property="og:title" content={fullSeo.metaTitle} />
      <meta name="twitter:title" content={fullSeo.metaTitle} />

      <meta name="description" content={fullSeo.metaDescription} />
      <meta property="og:description" content={fullSeo.metaDescription} />
      <meta name="twitter:description" content={fullSeo.metaDescription} />

      <meta property="og:image" content={fullSeo.shareImage} />
      <meta name="twitter:image" content={fullSeo.shareImage} />
      <meta name="image" content={fullSeo.shareImage} />

      <meta property="og:type" content={fullSeo.ogType} />
      <meta property="og:url" content="https://motorbikepacker.com" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default Seo;
